import React from "react";

import MainPage from "./MainPage";

const App = () => {
    return (
        <MainPage />
    );
};

export default App;