import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.scss";

import App from "./components/App";

ReactDOM.render(
    <App />,
    document.querySelector("#root")
);